import './App.css';
import Header from './components/Header';
import WorkGrid from './components/WorkGrid';
import SingleItemView from './components/VideoSingleView';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<WorkGrid />} />
          <Route path="/videos/:id" element={<SingleItemView />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
