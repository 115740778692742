import React from 'react';
import '../styles/WorkItem.css';
import { Link } from 'react-router-dom';

const WorkItem = ({ id, title, thumbnail}) => {
  const imageUrl = `${process.env.REACT_APP_STRAPI_URL}${thumbnail.url}`;

  return (
    <div className="work-item">
      <Link to={`/videos/${id}`}>
        <h3>{title} {thumbnail.previewUrl}</h3>
        <img src={imageUrl} />
      </Link>
    </div>
  );
};

export default WorkItem;
