import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

const Header = () => {
  const [links, setLinks] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_STRAPI_URL}/api/enlace`)
      .then(response => response.json())
      .then(data => {setLinks(data.data)})
      .catch(error => console.error('Error fetching links:', error));
  }, []);

  return (
    <header className="header">
      <div className="header-link">
        <a target='_blank' rel="noreferrer" href={links.izquierdaUrl}>{links.izquierdaTitle}</a>
      </div>
      <Link to={"/"}>
        <h1 className="header-title">ADRIAN MONTECARLO</h1>
      </Link>
      <div className="header-link">
        <a target='_blank' rel="noreferrer" href={links.derechaUrl}>{links.derechaTitle}</a>
      </div>
    </header>
  );
};

export default Header;
