import React from 'react';
import { useState, useEffect } from 'react';
import WorkItem from './WorkItem';
import '../styles/WorkGrid.css';

const WorkGrid = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    console.log(process.env.REACT_APP_STRAPI_URL);

    fetch(`${process.env.REACT_APP_STRAPI_URL}/api/videos?populate=thumbnail`)
      .then(response => response.json())
      .then(data => setVideos(data.data))
      .catch(error => console.error('Error fetching videos:', error));
  }, []);

  return (
    <div className="work-grid">
      {videos.map((video, index) => {
        return (
          <WorkItem key={video.id} id={video.documentId} title={video.title} thumbnail={video.thumbnail}/>
        );
      })}
    </div>
  );
};

export default WorkGrid;
