import { useState, useEffect } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/VideoSingleView.css';

const getYoutubeId = (url) => {
  const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;  // Return the video ID or null if not found
};

const VideoSingleView = () => {
  const [video, setVideo] = useState({});
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const { id } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_STRAPI_URL}/api/videos/${id}`)
      .then(response => response.json())
      .then(data => {
        setVideo(data.data);
        setYoutubeUrl(`https://www.youtube.com/embed/${getYoutubeId(data.data.video_url)}`)
      })
      .catch(error => console.error('Error fetching videos:', error));
  }, [id]);

  if (!video) {
    return <p>Cargando...</p>;
  }

  return (
    <div className='single-video'>
        <iframe 
            width="100%" height="auto" 
            src={youtubeUrl}
            title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
	    <h3>{video.title}</h3>
    </div>
  );
};

export default VideoSingleView;
